/* Stylesheet for the 'portlet.youknow' Plone theme */
.sabiasque {
  border: 1px solid #cccccc;
  padding: 1em;
  background-color: white;
  border-radius: 10px;
  margin-top: 1em;
}

.sabiasque img.sabiasqueimage {
  max-width: 37%;
  border: none;
  margin: 0 1em 0.5em 0;
  float: left;
}

.titulosabiasque {
  color: #007A2E;
  text-transform: none;
  margin: 0 0 0.3em 0;
  line-height: 1em;
}

.sabiasqueitem {
  line-height: 1.5em;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1em;
}

.sabiasquetexto {
  font-size: 125%;
}

.sabiasqueitem a {
  color: black;
  font-weight: bold;
}

.sabiasqueitem a:hover,
.sabiasqueitem a:active,
.sabiasqueitem a:focus {
  color: #007A2E;
}

/* Enlaces en linea en lugar de en bloque como en el resto */
.sabiasqueitem a.internal-link,
.sabiasqueitem a.external-link,
.sabiasqueitem a span.tituloArchivo {
  display: inline;
}

.portaltype-plone-site .titulosabiasque {
  color: #333;
  font-size: 2.6em;
}
.portaltype-plone-site .sabiasque {
  border: none;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 2em;
}
.portaltype-plone-site .sabiasqueitem {
  display: flex;
  align-items: center;
  margin-top: 1.8em;
}
.portaltype-plone-site .sabiasqueitem img.image-richtext {
  border-radius: 50%;
  border: 0.4em solid #6191b0;
  width: 13.5em;
  height: 13.5em;
  object-fit: cover;
  max-width: none;
}
.portaltype-plone-site .sabiasqueitem a:hover,
.portaltype-plone-site .sabiasqueitem a:active,
.portaltype-plone-site .sabiasqueitem a:focus {
  color: black;
}
.portaltype-plone-site .sabiasqueitem:nth-child(odd) {
  text-align: right;
  flex-direction: row-reverse;
}
.portaltype-plone-site .sabiasqueitem:nth-child(odd) img.image-richtext {
  margin: 0.5em 0 0.5em 1em;
}
@media (max-width: 1024px) {
  .portaltype-plone-site .sabiasqueitem img.image-richtext {
    width: 10em;
    height: 10em;
  }
}
@media (max-width: 767px) {
  .portaltype-plone-site .sabiasqueitem img.image-richtext {
    width: 15em;
    height: 15em;
  }
}
@media (max-width: 480px) {
  .portaltype-plone-site .titulosabiasque {
    text-align: center;
  }
  .portaltype-plone-site .sabiasqueitem,
  .portaltype-plone-site .sabiasqueitem:nth-child(odd) {
    display: block;
    text-align: center;
    margin-top: 2.2em;
  }
  .portaltype-plone-site .sabiasqueitem img.image-richtext,
  .portaltype-plone-site .sabiasqueitem:nth-child(odd) img.image-richtext {
    margin: 0 auto 1em auto;
    float: none;
  }
  .portaltype-plone-site .sabiasqueitem .sabiasquetexto,
  .portaltype-plone-site .sabiasqueitem:nth-child(odd) .sabiasquetexto {
    display: block;
  }
}
.portaltype-plone-site.palette-highcontrast .titulosabiasque {
  color: white;
}
.portaltype-plone-site.palette-highcontrast .sabiasqueitem img.image-richtext {
  border-color: white;
}
.portaltype-plone-site.palette-highcontrast .sabiasqueitem a {
  display: inline-block;
  border: none;
}
.portaltype-plone-site.palette-highcontrast .sabiasqueitem a:hover,
.portaltype-plone-site.palette-highcontrast .sabiasqueitem a:active,
.portaltype-plone-site.palette-highcontrast .sabiasqueitem a:focus {
  background-color: transparent !important;
}

